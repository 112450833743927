
html {
  background-color: black;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1920' height='3000' preserveAspectRatio='none' viewBox='0 0 1920 3000'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1038%26quot%3b)' fill='none'%3e%3crect width='1920' height='3000' x='0' y='0' fill='url(%26quot%3b%23SvgjsLinearGradient1039%26quot%3b)'%3e%3c/rect%3e%3cpath d='M1920 0L1775.01 0L1920 1408.18z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1775.01 0L1920 1408.18L1920 2266.04L1611.37 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M1611.37 0L1920 2266.04L1920 2567.2L1285.3999999999999 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M1285.4 0L1920 2567.2L1920 2726.1499999999996L536.1200000000001 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 3000L875.04 3000L0 2018.65z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 2018.65L875.04 3000L916.66 3000L0 1778.8600000000001z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 1778.86L916.66 3000L926.35 3000L0 825.9699999999999z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 825.9699999999998L926.35 3000L1514.33 3000L0 344.1699999999998z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1038'%3e%3crect width='1920' height='3000' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='50%25' y1='0%25' x2='50%25' y2='100%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1039'%3e%3cstop stop-color='rgba(16%2c 12%2c 39%2c 1)' offset='0.26'%3e%3c/stop%3e%3cstop stop-color='rgba(21%2c 16%2c 51%2c 1)' offset='0.68'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
}
* {
  padding:0;
  margin:0;
  box-sizing:border-box;
  font-family:Arial, Helvetica, sans-serif;
  font-weight:200;
}


::-webkit-scrollbar {
  width: .5vw;
}

/* Track */
::-webkit-scrollbar-track {
  background:rgb(0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color:rgb(31, 31, 31);
}