* {
  color:white;
}

.spinContainer {
  height:100vh;
  width:100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}