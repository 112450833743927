
.contactPageContainer {
    display:flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vw;
}

.contactForm {
    margin-top:10vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width:40%;
    border: 1px solid #d699ff80;
    box-shadow: 0px 0px 14px 2px #d699ff80;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='1000' height='700' preserveAspectRatio='none' viewBox='0 0 1000 700'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1012%26quot%3b)' fill='none'%3e%3crect width='1000' height='700' x='0' y='0' fill='url(%26quot%3b%23SvgjsLinearGradient1013%26quot%3b)'%3e%3c/rect%3e%3cpath d='M0 0L92.04 0L0 83.63z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M0 83.63L92.04 0L144.52 0L0 425.38z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M0 425.38L144.52 0L450.45000000000005 0L0 529.66z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M0 529.66L450.45000000000005 0L622.3800000000001 0L0 533.23z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M1000 700L562.3399999999999 700L1000 431.43z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M1000 431.43L562.3399999999999 700L519.1899999999999 700L1000 313.95z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M1000 313.95L519.19 700L456.50000000000006 700L1000 189.57z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M1000 189.57L456.5 700L452.46 700L1000 75.11z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1012'%3e%3crect width='1000' height='700' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='7.5%25' y1='-10.71%25' x2='92.5%25' y2='110.71%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1013'%3e%3cstop stop-color='rgba(33%2c 23%2c 75%2c 1)' offset='0'%3e%3c/stop%3e%3cstop stop-color='rgba(37%2c 45%2c 87%2c 1)' offset='0.49'%3e%3c/stop%3e%3cstop stop-color='rgba(0%2c 0%2c 0%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
}

.contactForm > * {
    padding:1vw;
    margin:1vw;
}

.formInput {
    width:80%;
    text-align: center;
    color:rgb(75, 20, 112);
    font-size:medium;
}

.sendButton {
    background-color: white;
    color:rgb(75, 20, 112);
    cursor:pointer;
    transition:transform .5s ease-in-out;
}

.sendButton:hover {
    transform: scale(1.2)
}

@media screen and (max-width:450px){
    .contactForm {
        margin-top:15vh;
        width:80%;
        height:40vh;
        display:flex;
        justify-content: space-around;
        flex-direction: column;

    }

    .contactPageContainer {
        margin-bottom: 15vh;
    }
}