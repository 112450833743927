.projectCardContainer {
    display: flex;
    justify-content: top;
    align-items: center;
    flex-direction: column;
    width: 20vw;
    height: 25vw;
    min-width: 20vw;
    min-height: 25vw;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.dev/svgjs' width='500' height='700' preserveAspectRatio='none' viewBox='0 0 500 700'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1388%26quot%3b)' fill='none'%3e%3crect width='500' height='700' x='0' y='0' fill='url(%26quot%3b%23SvgjsLinearGradient1389%26quot%3b)'%3e%3c/rect%3e%3cpath d='M500 0L280.53 0L500 178.12z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M280.53 0L500 178.12L500 385.24L226.67999999999998 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M226.68 0L500 385.24L500 435.65L146.92000000000002 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M146.92000000000002 0L500 435.65L500 528.5899999999999L139.20000000000002 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 700L187.38 700L0 475.06z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 475.06L187.38 700L203.32 700L0 467.86z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 467.86L203.32 700L340.53 700L0 270.11z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 270.11L340.53 700L371.38 700L0 223.63000000000002z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1388'%3e%3crect width='500' height='700' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='110%25' y1='7.14%25' x2='-10%25' y2='92.86%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1389'%3e%3cstop stop-color='rgba(16%2c 12%2c 39%2c 1)' offset='0.12'%3e%3c/stop%3e%3cstop stop-color='rgba(54%2c 44%2c 113%2c 1)' offset='0.81'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
    border-radius: 1vh;
    text-align: left;
    margin: 3vw;
    margin-bottom: 2vw;
    margin-top: 0;
    transition: transform ease-in-out .4s;
    border: 1px solid #d699ff80;
    box-shadow: 0px 0px 14px 2px #d699ff80;
}

.projectCardContainer:hover {
    transform: translateY(-20px)
}


.imageContainer {
    width: 80%;
    height: 65%;
    display: flex;
    align-items: last baseline;
}

.image {
    border-radius: 1.5vw;
    width: 100%;
    padding: 1vw;
}

.titleLinkContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding:0 1vh;
}

.titleLinkContainer>* {
    margin: 1vh;
}

.githubLogo {
    width: 2vw;
    height: 2vw;
    border-radius: .5vw;
}

.summary {
    text-align: left;
    font-size: small;
    width: 100%;
    padding: 2vh;
}

@media (max-width:730px) {
    .projectCardContainer {
        width: 70vw;
        height: fit-content;
        margin: 5vh 0;
    }
}