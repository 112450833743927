
.sectionTitle {
    margin:5vw;
    font-weight:400;
    font-size:xx-large;
}

.genericContainer {
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
