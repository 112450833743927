
.mainContainer {
    margin:5vh 0;
    display:flex;
    align-items:center;
    max-width:80vw;
    flex-wrap: wrap;
}


@media (max-width:450px){
    .mainContainer {
        flex-direction: column;
        width:100%;
        align-items: center;
        justify-content: center;
        max-width: 100vw;
    }
}

