.footer {
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: top;
  width: 100%;
  z-index: 100;
  height: 6vw;
}

.list {
  display: flex;
  justify-content: space-around;
  align-items: top;
  width: 50%;
  list-style-type: none;
}

.list > * {
  cursor: pointer;
  max-height: 50%;
}

.insta,
.github,
.linkedin {
  width: 2.5vw;
  height: 2.5vw;
}

.github {
  height: 3vw;
  width: 3vw;
  border-radius: 2.5vw;
}

@media screen and (max-width: 450px) {
  .footer {
    bottom: 0;
  }
  .insta,
  .github,
  .linkedin {
    width: 7vh;
    height: 7vh;
    padding: 1vh;
  }

  .list {
    width:70%;
  }

  .github {
    border-radius: 2vh;
  }
}
