.headerContainer {
  margin: 4vw;
  display: flex;
  justify-content: center;
  margin-bottom: 11vw;
}

.outerAbout {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ray {
  width: 30vh;
  opacity: 1;
  border-radius: 10px;
  margin: 1vw;
  box-shadow: 0px 0px 14px 2px #d699ff80;
  border:1px solid #d699ff80;
}

.aboutBar {
  color: white;
}

.aboutBar > * {
  padding: 2vh 2vh;
}

.main {
  height: auto;
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-start;
}

.helloTitle,
.myDescription,
.welcome,
.introPara {
  transition: transform 0.5s ease;
  font-size:large;
}

.helloTitle {
  font-size:x-large;
}

.helloTitle:hover,
.myDescription:hover,
.welcome:hover,
.introPara:hover {
  transform: scale(1.05);
}

.noteCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 5vh 5vh;
  margin-bottom:10vh;
}



@media screen and (max-width: 450px) {
  .outerAbout {
    flex-direction: column-reverse;
  }
  .aboutBar > * {
    text-align: center;
  }
  .helloTitle {
    padding-top: 4vh;
  }
}
